import styles from "../../scss/components/Fanfest/_Map.module.scss"
import React, { memo } from "react"
import { ComposableMap, Geographies, Geography } from "react-simple-maps"
import geoJson from "./utils/provincias.json"
const geoUrl = geoJson

const provinceStyles = {
  default: {
    fill: "rgba(203, 203, 203, 0.1)",
    stroke: "rgba(255,255,255,0.4)",
    strokeWidth: 0.1,
    outline: "none",
    transition: "all 250ms",
  },
  hover: {
    fill: "rgba(255,255,255,0.1)",
    stroke: "rgba(255,255,255,0.4)",
    strokeWidth: 0.1,
    outline: "none",
    transition: "all 250ms",
    cursor: "default",
  },
  pressed: {
    fill: "rgba(203, 203, 203, 0.1)",
    outline: "none",
    strokeWidth: 0.1,
    transition: "all 250ms",
  },
}
const provinceStylesCaba = {
  default: {
    fill: "transparent",
    stroke: "#ffffff",
    strokeWidth: 0.19,
    outline: "none",
    transition: "all 250ms",
  },
  hover: {
    fill: "#ffffff",
    outline: "none",
    transition: "all 250ms",
    cursor: "pointer",
  },
  pressed: {
    fill: "#ffffff",
    stroke: "#ffffff",
    strokeWidth: 0.55,
    outline: "none",
    transition: "all 250ms",
  },
}

const Map = ({
  setTooltipContent,
  setIsProvince,
  isProvince,
  setSelectedProvince,
  selectedProvince,
  setIsArtistSelected,
  isMobile,
  isSafari,
  eventData,
  setSelectedEvent,
  eventos,
}) => {
  const provinceBackground = {
    width: "400px",
    height: "400px",
  }
  const provinceBackgroundMobile = {
    width: "300px",
    height: "300px",
  }
  const handlerClickBA = geo => {
    if (
      eventos.filter(event => {
        return event.node.province === geo.properties.NAME
      }).length >= 1
    ) {
      const province = geo.properties
      setSelectedProvince(province)
      setIsProvince(true)
    } else {
      console.log("No hay eventos")
    }
  }

  function handleClickEvent2(event) {
    if (isMobile) {
      setIsProvince(false)
    }
    setIsArtistSelected(true)
    setSelectedEvent(event)
    window.dataLayer.push({
      event: "GAEvent",
      event_category: "Bud Music",
      event_action: `${event.node.province}`,
      event_label: `${event.node.name}`,
      interaction: true,
      component_name: "boton_participa_por_entradas",
    })
  }

  return (
    <div
      style={
        !isProvince
          ? isMobile
            ? {
                width: "100%",
                maxWidth: 980,
                margin: "0 auto",
                marginTop: "-310%",
              }
            : {
                width: "100%",
                maxWidth: 980,
                margin: "0 auto",
                marginTop: "-260%",
              }
          : null
      }
      className={
        !isProvince ? (isMobile ? null : null) : styles.wrapperProvince
      }
    >
      {!isProvince ? (
        <ComposableMap
          projectionConfig={{
            scale: 0,
            rotate: [60, 0, 0],
          }}
          width={isMobile ? 80 : 100}
          // height={400}
          height={400}
          style={
            isMobile
              ? { width: "100%", height: "auto", marginLeft: "35px" }
              : { width: "100%", height: "auto", marginLeft: "60px" }
          }
        >
          <Geographies geography={geoUrl} disableOptimization>
            {({ geographies }) =>
              geographies.map(geo => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={() => {
                    const { NAME, POP_EST } = geo.properties
                    setTooltipContent(`${NAME}`)
                  }}
                  onMouseLeave={() => {
                    setTooltipContent("")
                  }}
                  onClick={() => handlerClickBA(geo)}
                  style={
                    eventos.filter(event => {
                      return event.node.province === geo.properties.NAME
                    }).length >= 1
                      ? provinceStylesCaba
                      : provinceStyles
                  }
                />
              ))
            }
          </Geographies>
        </ComposableMap>
      ) : (
        <div className={styles.provinceImgContainer}>
          <img
            src={require(`../../images/TheBirraBehind/Provinces/${selectedProvince.URL}`)}
            style={isMobile ? provinceBackgroundMobile : provinceBackground}
          />
          <div
            className={
              !isMobile
                ? !isSafari
                  ? styles.locationContainer
                  : styles.locationContainerSafari
                : styles.locationContainerMobile
            }
          >
            {eventos.map(event => {
              if (
                selectedProvince.NAME === event.node.province &&
                event.node.visible
              ) {
                return (
                  <div
                    key={event.node.id}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      position: "absolute",
                      top: isMobile ? event.node.top * 1.5 : event.node.top * 2,
                      left: isMobile
                        ? event.node.left * 1.5
                        : event.node.left * 2,

                      // marginTop: isMobile
                      //     ? event.mobileTop ? event.mobileTop - 110 + "px" : event.margin - 110 + "px"
                      //     : event.margin - 200 + "px",
                      // marginLeft: isMobile
                      //     ? ( event.left ? event.left +"px": -30 + "px")
                      //     : event.margin / 30 + "px",
                      // marginTop: Math.floor(Math.random() * 200 - 200) + "px",
                      // marginLeft: Math.floor(Math.random() * 3) + "px",
                    }}
                  >
                    <h1 className={styles.locationText}>{event.node.name}</h1>
                    <div
                      className={styles.locationPoint}
                      onClick={() => handleClickEvent2(event)}
                    />
                  </div>
                )
              } else {
                console.log("Nada")
              }
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(Map)
