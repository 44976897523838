import React, { useEffect } from "react"
import Div100vh from "react-div-100vh"
import useDeviceDetect from "./hooks/useDevice"
import styles from "../../scss/components/Fanfest/_Main.module.scss"

// Images & Icons
import logo from "../../images/TheBirraBehind/logobud.svg"
import qatarlogo from "../../images/Fanfest/qatarlogo.png"
// import ilus from "../../images/TheBirraBehind/ilushome.svg"
import texture from "../../images/TheBirraBehind/textura-fondo.svg"
import redIcon from "../../images/TheBirraBehind/bud-red-icon.png"

export default function Main() {
  const { isSafari } = useDeviceDetect()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Div100vh>
    <div className={isSafari ? styles.containerSafari : styles.container}>
      <img src={logo} className={styles.logo} />
      <img src={qatarlogo} className={styles.qatar} />
    </div>
    </Div100vh>

  )
}
