import React, { useState, useEffect } from "react"
import styles from "./../../scss/components/Fanfest/_StepForm.module.scss"
import BackIcon from "./../../images/BackToLive/backIcon.png"
import Step1 from "./Steps/Step1"
import Step2 from "./Steps/Step2"
import useDeviceDetect from "./hooks/useDevice"

export default function StepForm({ selectedProvince, selectedEvent,setStep }) {
  const [page, setPage] = useState(1)
  const[error,setError] = useState("")
  const [values, setValues] = useState({
    fullname:"",
    email:"",
    state:"",
    city:"",
    instagram:"",
    dateofbirth:"",
    preferenceQuestion:"",
  })
  const { isMobile, isSafari } = useDeviceDetect()

  const handleChange = e => {
    const { name, value } = e.target
    setValues(form => ({ ...values, [name]: value }))
  }

  let step
  if (page === 1) {
    step = (
      <Step1
      handleChange={handleChange}
        setValues={setValues}
        setPage={setPage}
        propValues={values}
        isMobile={isMobile}
        isSafari={isSafari}
        setStep={setStep}
        setError={setError}
        error={error}
      />
    )
  }
   else {
    step = (
      <Step2
      handleChange={handleChange}
        setValues={setValues}
        propValues={values}
        selectedProvince={selectedProvince}
        selectedEvent={selectedEvent}
        isMobile={isMobile}
        isSafari={isSafari}
        setStep={setStep}
        setError={setError}
        error={error}
      />
    )
  }


  return (
    <>
      <div className={styles.stepFormContainer}>
        <div className={styles.formWrap}>
          <div className={styles.firstColumn}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h1 className={styles.pageTextOne}>{page}</h1>
              <h1 className={styles.pageTextTwo}>/2</h1>
            </div>
            {page !== 1 ? (
              <div className={styles.backBtn}>
                <a
                  className={styles.btnWrapper}
                  onClick={() => setPage(page - 1)}
                >
                  <img
                    src={BackIcon}
                    style={
                      isMobile
                        ? { width: "12px", objectFit: "contain" }
                        : { objectFit: "contain" }
                    }
                  />
                  <h3>Volver</h3>
                </a>
              </div>
            ) : null}
          </div>
          <div className={styles.secondColumn}>{step}</div>
        </div>
      </div>
    </>
  )
}
